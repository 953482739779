<template>
	<div class="contact-box" style="padding-bottom: 20px;">
		<div class="container">
			<div class="row">
				<div class="col-lg-12">
					<div class="title-box wow wow-not-repeat zoomInDown" id="gift">
						<h2><span class="custom-font-color">~ Wedding Gift ~</span></h2>
                        <p>Jika Bapak/ Ibu/ Saudara/i serta kerabat sekalian ingin memberikan hadiah, dapat melalui link dibawah ini:</p>
                        <button type="button" class="btn custom-color mt-10" @click="giftModal()">
                            Send Gift
                        </button>
					</div>
				</div>
			</div>
		</div>

        <div class="modal fade" id="giftModal" tabindex="-1" role="dialog" aria-labelledby="giftModalTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="pt-10 pb-10 row text-center justify-content-center">
                            <div class="col-md-6 col-sm-12">
                                <div class="pb-30">
                                    <img src="/images/logo-bri.png" alt="logo-bri" width="90px;">
                                    <p class="pt-2">
                                        <span id="copy1">{{ copy1 }}</span>
                                        <br>a.n {{ this.female }}
                                    </p>
                                    <button type="button" class="btn custom-color"  :id="copy1" @click="getCopyText">
                                        Copy Number
                                    </button>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-12">
                                <div class="pb-20">
                                    <img src="/images/logo-mandiri.png" alt="logo-mandiri" width="120px;">
                                    <p class="pt-2">
                                        <span id="copy2">{{ copy2 }}</span>
                                        <br>a.n {{ this.male }}
                                    </p>
                                    <button type="button" class="btn custom-color" :id="copy2" @click="getCopyText">
                                        Copy Number
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

	</div>
</template>

<script>
export default {
    name: 'Gift',
    data() {
        return {
            copy1: 339901046368533,
            copy2: 1030007144104
        }
    },
    methods: {
        getCopyText(e) {
            var copyText = e.target.id
            navigator.clipboard.writeText(copyText);
            this.$toast.default('Copied', {
                position: "bottom",
                dismissible: true
            })
        },
        giftModal() {
            $('#giftModal').modal('show');
        }
    }
}
</script>

<style scoped>
    @media (max-width: 360px) {
        #giftModal {
            font-size: 14px;
        }
    }
</style>