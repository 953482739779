import Vue from 'vue'
import VueRouter from 'vue-router'

// Init view
import Index from '@/components/Index.vue'
import Shortlink from '@/module/Shortlink.vue'

Vue.use(VueRouter);

export default new VueRouter({
    linkActiveClass: 'active',
    linkExactActiveClass: 'active',
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'default',
            component: Index
        },
        {
            path: '/shortlink',
            name: 'Shortlink',
            component: Shortlink
        }
    ]
})