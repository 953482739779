<template>
	<div id="intro">
		<!-- Mobile View -->
		<div id="owl-carousel" class="owl-carousel owl-theme">
			<div class="owl-slide d-flex align-items-center cover" :style="{ backgroundImage: 'url(' + image_mobile[0] + ')' }">
				<div class="lbox-caption">
					<div class="lbox-details">
						<h1>{{ this.coupleName }}</h1>
						<h2>Wedding Invitation</h2>
						<p class="pt-2">
							<strong class="custom-font-color">{{this.textDate}}</strong>
						</p>
						<a href="#couple" class="btns"><i class="fa fa-angle-double-down wow bounceIn infinite" data-wow-iteration="10"></i></a>
					</div>
				</div>
			</div>
			<div class="owl-slide d-flex align-items-center cover" :style="{ backgroundImage: 'url(' + image_mobile[1] + ')' }">
				<div class="lbox-caption">
					<div class="lbox-details">
						<h1>{{ this.coupleName }}</h1>
						<h2>We are Getting Married</h2>
						<p class="pt-2">
							<strong class="custom-font-color">{{this.textDate}}</strong>
						</p>
						<a href="#couple" class="btns"><i class="fa fa-angle-double-down wow bounceIn infinite" data-wow-iteration="10"></i></a>
					</div>
				</div>
			</div>
			<div class="owl-slide d-flex align-items-center cover" :style="{ backgroundImage: 'url(' + image_mobile[2] + ')' }">
				<div class="lbox-caption">
					<div class="lbox-details">
						<h1>{{ this.coupleName }}</h1>
						<h2>You're Invited to<br>Our Special Day</h2>
						<p class="pt-2">
							<strong class="custom-font-color">{{this.textDate}}</strong>
						</p>
						<a href="#couple" class="btns"><i class="fa fa-angle-double-down wow bounceIn infinite" data-wow-iteration="10"></i></a>
					</div>
				</div>
			</div>
		</div>

		<!-- Desktop View -->
		<div id="pogoSlider" class="ulockd-home-slider">
			<div class="container-fluid">
				<div class="row">
					<div class="pogoSlider" id="js-main-slider">
						<div class="pogoSlider-slide" data-transition="blocksReveal" data-duration="1500" :style="{ backgroundImage: 'url(' + image_desktop[0] + ')', backgroundPosition: 'center' }">
							<div class="lbox-caption">
								<div class="lbox-details">
									<h1>{{ this.coupleName }}</h1>
									<h2>Wedding Invitation</h2>
									<p>
										<strong>{{this.textDate}}</strong>
									</p>
									<a href="#couple" class="btns"><i class="fa fa-angle-double-down wow bounceIn infinite" data-wow-iteration="10"></i></a>
								</div>
							</div>
						</div>
						<div class="pogoSlider-slide" data-transition="blocksReveal" data-duration="1500" :style="{ backgroundImage: 'url(' + image_desktop[1] + ')', backgroundPosition: 'center' }">
							<div class="lbox-caption">
								<div class="lbox-details">
									<h1>{{ this.coupleName }}</h1>
									<h2>We are Getting Married</h2>
									<p>
										<strong>{{this.textDate}}</strong>
									</p>
									<a href="#couple" class="btns"><i class="fa fa-angle-double-down wow bounceIn infinite" data-wow-iteration="10"></i></a>
								</div>
							</div>
						</div>
						<div class="pogoSlider-slide" data-transition="zipReveal" data-duration="2000" :style="{ backgroundImage: 'url(' + image_desktop[2] + ')', backgroundPosition: 'center' }">
							<div class="lbox-caption">
								<div class="lbox-details">
									<h1>{{ this.coupleName }}</h1>
									<h2>You're Invited to Our Special Day</h2>
									<p>
										<strong>{{this.textDate}}</strong>
									</p>
									<a href="#couple" class="btns"><i class="fa fa-angle-double-down wow bounceIn infinite" data-wow-iteration="10"></i></a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  name: 'Banner',
  data() {
	  return {
	  	image_mobile: ['/photos/slider/mobile-3.jpg', '/photos/slider/mobile-2.jpg', '/photos/slider/mobile-1.jpg'],
	  	image_desktop: ['/photos/slider/desktop-1.jpg', '/photos/slider/desktop-3.jpg', '/photos/slider/desktop-2.jpg'],
	  }
  },
  props: {
    msg: String
  },
}
</script>

<style scoped>
	* {
		padding: 0;
		margin: 0;
		box-sizing: border-box;
	}

	.static {
		position: static;
	}

	.cover {
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}

	.owl-carousel .owl-slide {
		position: relative;
		height: 100vh;
		/*background-color: lightgray;*/
	}

	.owl-carousel .owl-slide-animated {
		transform: translateX(20px);
		opacity: 0;
		visibility: hidden;
		transition: all 0.05s;
	}

	.owl-carousel .owl-slide-animated.is-transitioned {
		transform: none;
		opacity: 1;
		visibility: visible;
		transition: all 0.5s;
	}

	.owl-carousel .owl-slide-title.is-transitioned {
		transition-delay: 0.2s;
	}

	.owl-carousel .owl-slide-subtitle.is-transitioned {
		transition-delay: 0.35s;
	}

	.owl-carousel .owl-slide-cta.is-transitioned {
		transition-delay: 0.5s;
	}

	.owl-carousel .owl-dots,
	.owl-carousel .owl-nav {
		position: absolute;
	}

	.owl-carousel .owl-dots .owl-dot,
	.owl-carousel .owl-nav [class*="owl-"]:focus {
		outline: none;
	}

	.owl-carousel .owl-dots .owl-dot span {
		background: transparent;
		border: 1px solid var(--main-black-color);
		transition: all 0.2s ease;
	}

	.owl-carousel .owl-dots .owl-dot:hover span,
	.owl-carousel .owl-dots .owl-dot.active span {
		background: var(--main-black-color);
	}

	.owl-carousel .owl-nav {
		left: 50%;
		top: 10%;
		transform: translateX(-50%);
		margin: 0;
	}

	.owl-carousel .owl-nav svg {
		opacity: 0.3;
		transition: opacity 0.3s;
	}

	.owl-carousel .owl-nav button:hover svg {
		opacity: 1;
	}

	.owl-carousel .owl-nav [class*="owl-"]:hover {
		background: transparent;
	}

	section {
		display: none;
	}

	@media (min-width: 460px) {
		.owl-carousel .owl-nav {
			top: 5%;
		}
		.owl-carousel .owl-nav svg {
		width: 24px;
		height: 24px;
		}
	}

	/* 2 */
	#pogoSlider {display: block;}
	#owl-carousel {display: none;}
	@media (max-width: 1200px) {
		html, body {
			height: 100%;
		}

		#pogoSlider {
			display: none;
		}

		#owl-carousel {
			display: block;
			width: 100%;
			height: 100%;
		}
	}
</style>
