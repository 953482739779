<template>
	<div class="shortlink">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 mb-3">
                    <p class="text-center">
                        ondangan.<font color="blue">website</font> - url generator
                    </p>
                </div>
                <div class="col-lg-12">
                    <form @submit.prevent="generateUrl">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <small for="text_messages">Teks Pesan</small>
                                    <textarea
                                        v-model="text_messages"
                                        ref="text_messages" name="text_messages" id="text_messages" class="form-control" rows="15" placeholder="Input messages here">
                                    </textarea>
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-12">
                                <div class="form-group">
                                    <small for="recipient_name">Nama Penerima <span class="text-danger">*</span></small>
                                    <input
                                        v-model="recipient_name"
                                        v-bind:class="!recipient_name && is_required_recipient_name ? 'is-invalid' : ''"
                                        type="text" id="recipient_name" name="recipient_name" class="form-control" autocomplete="off" placeholder="Input here">
                                    <small class="form-text text-danger" v-if="!recipient_name">{{ is_required_recipient_name }}</small>
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-12">
                                <div class="form-group">
                                    <small for="recipient_number">WhatsApp Number (Optional)</small>
                                    <input
                                        v-model="recipient_number"
                                        @keydown.space.prevent
                                        type="number" id="recipient_number" name="recipient_number" class="form-control" autocomplete="off" placeholder="Start with 0 or 62">
                                </div>
                            </div>
                        </div>
                        <br>
                        <button type="submit" ref="submit" class="btn btn-success">{{ recipient_number ? 'Share to WhatsApp' : 'Copy' }}</button> &nbsp;
                        <button v-on:click="clear" type="button" class="btn btn-danger">Reset</button>
                    </form>
                </div>
            </div>
        </div>
	</div>
</template>

<script>
	import Axios from 'axios'
    export default {
        name: 'Shortlink',
        data() {
            return {
                recipient_name: '',
                text_messages: '',
                recipient_number: '',
                is_required_recipient_name: '',
                is_required_text_messages: '',
            }
        },
        mounted() {
            if (localStorage.text_messages) {
                this.text_messages = localStorage.text_messages;
            }
        },
        watch: {
            text_messages(message) {
                localStorage.text_messages = message;
            }
        },
        methods: {
            generateNumber() {
                let recipient_number = this.recipient_number;
                if (recipient_number.charAt(0) == '0') {
                    let number = recipient_number.replace(recipient_number.charAt(0), '62');
                    this.recipient_number = number
                }
            },
            generateUrl() {
                if (!this.recipient_name) {
                    this.is_required_recipient_name = 'Recipient name is required'
                }

                if (this.recipient_name) {

                    this.generateNumber();

                    let url = window.location.origin+'?to='+encodeURIComponent(this.recipient_name)
                    if (!this.recipient_number) {
                        var input = document.createElement("textarea");
                        input.value = this.text_messages + '\n\n' +url
                        document.body.appendChild(input);
                        input.select();
                        document.execCommand("Copy");
                        input.remove();

                        this.$toast.default('Copied for '+this.recipient_name, {
                            duration: 5000,
                            position: "bottom",
                            dismissible: true,
                        })
                    }
                    else {
                        var message = encodeURIComponent(this.text_messages);
                        var wa = 'https://wa.me/'+this.recipient_number+'?text='+message+'%0a%0a'+encodeURIComponent(url);
                        window.open(wa, '_blank', 'noopener')
                    }
                }
            },
            clear() {
                this.recipient_name = ''
                this.recipient_number = ''
                this.is_required_recipient_name = ''
                this.is_required_text_messages = ''
                this.is_required_recipient_number = ''
            }
        }
    }
</script>

<style scoped>
    .shortlink {
        text-align: left;
        margin: 30px 0px 30px;
        font-family: 'system-ui';
    }
</style>