var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"tm-parallax"},[_c('div',{staticClass:"container-fluid tm-parallax-header"},[_c('div',{attrs:{"id":"event"}},[_vm._m(0),_c('div',{staticClass:"add-to-calendar",attrs:{"data-toggle":"tooltip","data-placement":"right","title":"Add to Calendar"},on:{"click":_vm.addCalendar}},[_c('span',{staticClass:"day wow bounceInUp",attrs:{"data-wow-delay":"0.4s"}},[_vm._v("Minggu, 05 November 2023")]),_c('br'),_c('span',{staticClass:"time wow bounceInUp",attrs:{"data-wow-delay":"0.5s"}},[_vm._v(_vm._s(this.textTime))]),_c('br'),_c('br')]),_c('div',{staticClass:"submit-button text-center wow fadeIn",attrs:{"data-wow-delay":"0.6s"}},[_c('span',{staticClass:"d-block d-sm-none",on:{"click":_vm.openMaps}},[_vm._m(1),_vm._v(" "),_c('br'),_vm._m(2),_c('br'),_vm._v("(Kediaman mempelai wanita) ")]),_c('span',{staticClass:"d-none d-xl-block",on:{"click":_vm.openMaps}},[_vm._m(3),_vm._v(" "),_c('br'),_vm._m(4),_c('br'),_vm._v("(Kediaman mempelai wanita) ")])]),_c('span',{staticClass:"before"},[_c('button',{staticClass:"btn custom-color wow zoomIn",staticStyle:{"text-decoration":"none"},attrs:{"data-wow-delay":"0.8s"},on:{"click":_vm.openMaps}},[_vm._v(" Open Maps ")])]),_vm._m(5),_vm._m(6),_c('p',{staticClass:"closing-event display-font wow fadeInUp",staticStyle:{"font-size":"18px","color":"white","padding-top":"5vw"},attrs:{"data-wow-delay":"1s"}},[_vm._v(" Merupakan suatu kebahagiaan bagi kami apabila Bapak/ Ibu/ Saudara/i serta kerabat sekalian berkenan hadir dan memberikan do'a restu kepada kami. ")])])])]),_vm._m(7)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-box wow bounceIn"},[_c('h2',[_c('span',{staticStyle:{"font-size":"38px"}},[_vm._v("Resepsi")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('small',[_c('i',[_vm._v("bertempat di:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("Jatimulyo Kedungrejo, Kemusu"),_c('br'),_vm._v("Boyolali, Jawa Tengah")])
},function (){var _vm=this,_c=_vm._self._c;return _c('small',[_c('i',[_vm._v("bertempat di:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticStyle:{"font-size":"18px","font-weight":"bold"}},[_vm._v("Jatimulyo Kedungrejo, Kemusu"),_c('br'),_vm._v("Boyolali, Jawa Tengah")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"after",staticStyle:{"display":"none"}},[_c('div',{staticClass:"text-center wow bounceIn",attrs:{"data-wow-iteration":"10","data-wow-delay":"1s"}},[_c('i',{staticClass:"fa fa-heart fa-3x"})]),_c('div',{staticClass:"wow bounceIn text-after",staticStyle:{"padding-top":"40px","padding-bottom":"40px"}},[_c('span',[_vm._v("Alhamdulillah SAH")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"countdown wow zoomIn",attrs:{"data-wow-delay":"1s"}},[_c('p',{staticClass:"footer"},[_vm._v("menuju waktu yang dinanti")]),_c('li',[_c('span',{staticClass:"days"},[_vm._v("00")]),_c('p',{staticClass:"days_ref"},[_vm._v("hari")])]),_c('li',{staticClass:"seperator"},[_vm._v(".")]),_c('li',[_c('span',{staticClass:"hours"},[_vm._v("00")]),_c('p',{staticClass:"hours_ref"},[_vm._v("jam")])]),_c('li',{staticClass:"seperator"},[_vm._v(":")]),_c('li',[_c('span',{staticClass:"minutes"},[_vm._v("00")]),_c('p',{staticClass:"minutes_ref"},[_vm._v("menit")])]),_c('li',{staticClass:"seperator"},[_vm._v(":")]),_c('li',[_c('span',{staticClass:"seconds"},[_vm._v("00")]),_c('p',{staticClass:"seconds_ref"},[_vm._v("detik")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container pt-5 pb-4"},[_c('div',{staticClass:"title-box wow bounceIn d-md-none"},[_c('h2',[_c('span',{staticClass:"custom-font-color"},[_vm._v("~ Protokol Covid-19 ~")])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('div',{staticClass:"row align-items-center about-main-info"},[_c('div',{staticClass:"col-lg-4 col-md-6 col-sm-12"},[_c('div',{staticClass:"about-img wow wow-not-repeat swing"},[_c('img',{staticClass:"img-fluid rounded d-block d-md-none",attrs:{"src":"images/info-1.png"}}),_c('img',{staticClass:"img-fluid rounded d-none d-md-block",attrs:{"src":"images/info-2.png"}})])]),_c('div',{staticClass:"col-lg-8 col-md-6 col-sm-12 wow flash text-center"},[_c('h2',{staticClass:"d-none d-md-block"},[_c('span',[_vm._v("~ Protokol Covid-19 ~")])]),_c('i',[_vm._v("Kami menghimbau para tamu undangan agar tetap menerapkan protokol kesehatan.")])])]),_c('span',{staticClass:"d-block d-sm-none"},[_c('br'),_c('hr')])])])])
}]

export { render, staticRenderFns }