<template>
	<div class="gallery-box">
		<div class="container-fluid">
			<div class="title-box wow bounceIn" id="gallery">
				<h2><span class="custom-font-color">~ Our Gallery ~</span></h2>
			</div>
			<div class="row">
				<div class="col-12">
					<div class="tab-content py-4">
						<div class="tab-pane active" id="tab1" style="margin-left:-15px;margin-right:-15px">
							<ul class="popup-gallery clearfix">
								<li v-for="(image, key) in images" :key="key" class="wow zoomInDown">
									<a :href="images[key]">
										<img class="img-fluid" :src="thumbs[key]" alt="single image">
										<span class="overlay"><i class="fa fa-heart-o" aria-hidden="true"></i></span>
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
    name: 'Gallery',
	data() {
		return {
			thumbs: [
				'photos/gallery/thumb (1).jpg',
				'photos/gallery/thumb (2).jpg',
				'photos/gallery/thumb (3).jpg',
				'photos/gallery/thumb (4).jpg',
				'photos/gallery/thumb (5).jpg',
				'photos/gallery/thumb (6).jpg',
				'photos/gallery/thumb (7).jpg',
				'photos/gallery/thumb (8).jpg',
			],
			images: [
				'photos/gallery/gallery-1-min.jpg',
				'photos/gallery/gallery-2-min.jpg',
				'photos/gallery/gallery-3-min.jpg',
				'photos/gallery/gallery-4-min.jpg',
				'photos/gallery/gallery-5-min.jpg',
				'photos/gallery/gallery-6-min.jpg',
				'photos/gallery/gallery-7-min.jpg',
				'photos/gallery/gallery-8-min.jpg',
			],
		}
	}
}
</script>

<style scoped>
	.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
		font-weight: bold;
	}

	.video {
		width: 50%; height: 535px;
	}

	@media (min-width: 768px) and (max-width: 991px) {
		.video {
			width: 90%; height: 375px;
		}
	}

	@media (max-width: 767px) {
		.video {
			width: 100%; height: 320px;
		}
	}
</style>