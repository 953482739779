<template>
    <router-view></router-view>
</template>

<script>
    export default {
        name: 'App',
        // created() {
        //     window.addEventListener('contextmenu', function(e) {
        //         e.preventDefault();
        //     }, false);
        // },
    }
</script>