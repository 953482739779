import moment from 'moment'
import "moment/locale/id";

const MyPlugin = {
    install(Vue, options) {
        Vue.prototype.male = 'Muhammad Ichsan Setiadi'
        Vue.prototype.female = 'Wiwik Sri Lestari'
        Vue.prototype.coupleName = 'Wiwik & Ichsan'
        Vue.prototype.textEvent = 'Wedding of Wiwik %26 Ichsan'
        Vue.prototype.textTime = 'Pukul 11.00 s.d 15.00'
        Vue.prototype.dateEvent = '20231105T110000Z+07:00/202311055T150000Z+07:00'
        Vue.prototype.locationEvent = 'Jatimulyo%20Kedungrejo,%20Kemusu,%20Boyolali,%20Jawa%20Tengah'
        Vue.prototype.urlEvent = 'More info -> https://ichsanwiwik.menujunikah.com'
        Vue.prototype.maps = 'https://maps.app.goo.gl/tiXWTtcb2sePfq4w7?g_st=iw'
        // Vue.prototype.youtube = 'https://www.youtube.com/embed/eThE7kCE5VQ?controls=0'
        Vue.prototype.recaptcha = '6LfVhvQfAAAAABbb42ftcasUwVMmfxuf5nsV9ai7'
        Vue.prototype.preloader = true
        Vue.prototype.modal = true

        Vue.mixin({
            data: function () {
                return {
                    textDate: '',
                    eventDate: ''
                }
            },
            methods: {
                format_date(param){
                    const date = "2023-11-05 15:00:00";
                    return moment(new Date(date)).format(param);
                },
            },
            created: function () {
                this.textDate = this.format_date('dddd, LL');
                this.eventDate = this.format_date('YYYY/MM/DD HH:mm:ss');
            }
        });
    }
}

export default MyPlugin