<template>
	<div>
		<div style="" class="tm-parallax">
			<div class="container-fluid tm-parallax-header">
				<div id="event">
					<div class="title-box wow bounceIn">
						<h2><span style="font-size: 38px;">Resepsi</span></h2>
					</div>
					<div class="add-to-calendar" v-on:click="addCalendar" data-toggle="tooltip" data-placement="right" title="Add to Calendar">
						<span class="day wow bounceInUp" data-wow-delay="0.4s">Minggu, 05 November 2023</span><br>
						<span class="time wow bounceInUp" data-wow-delay="0.5s">{{ this.textTime }}</span><br><br>
					</div>
					<div class="submit-button text-center wow fadeIn" data-wow-delay="0.6s">
						<span class="d-block d-sm-none" v-on:click="openMaps">
							<small><i>bertempat di:</i></small> <br/>
							<span style="font-weight: bold;">Jatimulyo Kedungrejo, Kemusu<br/>Boyolali, Jawa Tengah</span>
							<br/>(Kediaman mempelai wanita)
						</span>
						<span class="d-none d-xl-block" v-on:click="openMaps">
							<small><i>bertempat di:</i></small> <br/>
							<span style="font-size: 18px; font-weight: bold;">Jatimulyo Kedungrejo, Kemusu<br/>Boyolali, Jawa Tengah</span>
							<br/>(Kediaman mempelai wanita)
						</span>
					</div>
					<span class="before">
						<button v-on:click="openMaps" class="btn custom-color wow zoomIn" data-wow-delay="0.8s" style="text-decoration: none;">
							Open Maps
						</button>
					</span>
					<span class="after" style="display:none;">
						<div class="text-center wow bounceIn" data-wow-iteration="10" data-wow-delay="1s">
							<i class="fa fa-heart fa-3x"></i>
						</div>
						<div class="wow bounceIn text-after" style="padding-top: 40px; padding-bottom: 40px;">
							<span>Alhamdulillah SAH</span>
						</div>
					</span>

					<ul class="countdown wow zoomIn" data-wow-delay="1s">
						<p class="footer">menuju waktu yang dinanti</p>
						<li>
							<span class="days">00</span>
							<p class="days_ref">hari</p>
						</li>
						<li class="seperator">.</li>
						<li>
							<span class="hours">00</span>
							<p class="hours_ref">jam</p>
						</li>
						<li class="seperator">:</li>
						<li>
							<span class="minutes">00</span>
							<p class="minutes_ref">menit</p>
						</li>
						<li class="seperator">:</li>
						<li>
							<span class="seconds">00</span>
							<p class="seconds_ref">detik</p>
						</li>
					</ul>

					<p class="closing-event display-font wow fadeInUp" data-wow-delay="1s" style="font-size: 18px; color:white; padding-top:5vw;">
						Merupakan suatu kebahagiaan bagi kami apabila Bapak/ Ibu/ Saudara/i serta kerabat sekalian berkenan hadir dan memberikan do'a restu kepada kami.
					</p>
				</div>
			</div>
		</div>

		<div class="container pt-5 pb-4">
			<div class="title-box wow bounceIn d-md-none">
				<h2><span class="custom-font-color">~ Protokol Covid-19 ~</span></h2>
			</div>
			<div class="row">
				<div class="col-lg-12 col-md-12 col-sm-12">
					<div class="row align-items-center about-main-info">
						<div class="col-lg-4 col-md-6 col-sm-12">
							<div class="about-img wow wow-not-repeat swing">
								<img class="img-fluid rounded d-block d-md-none" src="images/info-1.png"/>
								<img class="img-fluid rounded d-none d-md-block" src="images/info-2.png"/>
							</div>
						</div>
						<div class="col-lg-8 col-md-6 col-sm-12 wow flash text-center">
							<h2 class="d-none d-md-block"><span>~ Protokol Covid-19 ~</span></h2>
							<i>Kami menghimbau para tamu undangan agar tetap menerapkan protokol kesehatan.</i>
						</div>
					</div>
					<span class="d-block d-sm-none">
						<br><hr>
					</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
    name: 'Event',
	methods: {
		addCalendar: function() {
			let url = "https://calendar.google.com/calendar/render?"+
						"action=TEMPLATE"+
						"&text="+this.textEvent+
						"&dates="+this.dateEvent+
						"&details="+this.urlEvent+
						"&location="+this.locationEvent+
						"&sf=true&output=xml";
			window.open(url, '_blank', 'noopener')
		},
		openMaps: function() {
			let url = this.maps;
			window.open(url, '_blank', 'noopener')
		}
	},
	mounted() {
		$('.countdown').downCount({
			date: this.eventDate,
			offset: +7
		}, function () {
			$('.countdown').hide();
			$('.before').hide();
			$('.after').show();
			$('#event').addClass('add-padding');
			$('.tm-parallax').css('height', '100%');
			$('.closing-event').text('“Baarakallahu laka wa baarakaa alaika wa jamaa bainakumaa fii khoir”');
		});
	},
}
</script>

<style scoped>
	/* image-paralax */
    .tm-parallax {
        width: 100%;
        height: 100vh;
        background-image: url('/photos/event/4.jpg');
        position: relative;
    }

    .tm-parallax-header {
		padding-top: 70px;
		text-align: center;
        background: rgba(0, 0, 0, 0.7);
        width: 100%;
        height: 100%;
        color: white;
    }

    /* countdown */
    ul.countdown {
        list-style: none;
        margin: 60px 0px 20px 0px;
        padding: 0;
        display: block;
        text-align: center;
    }
    ul.countdown li {
        display: inline-block;
    }
	ul.countdown li span {
		font-size: 80px;
		font-weight: 300;
        line-height: 80px;
	}
	ul.countdown li.seperator {
		font-size: 80px;
		line-height: 70px;
        vertical-align: top;
	}
    ul.countdown li p {
        color: #a7abb1;
        font-size: 14px;
    }
	.day {
		font-weight: bold;
		font-size: 35px;
	}
	.time {
		font-weight: bold;
		font-size: 26px;
	}
	.submit-button {
		padding-bottom: 60px;
	}
	.text-after span {
		font-size: 60px;
	}
	.add-to-calendar {
		cursor: pointer;
	}
	.add-padding {
		padding-top: 150px;
	}

	/* Desktop */
	@media (max-width: 1366px) {
		#event {
			margin-top: 65px;
		}
		.tm-parallax {
			height: 100%;
		}
		.tm-parallax-header {
			padding-top: 5px;
			padding-bottom: 35px;
		}
		.submit-button {
			padding-bottom: 30px;
		}
		ul.countdown li span {
			font-size: 60px;
		}
		ul.countdown li.seperator {
			font-size: 60px;
		}
		.day {
			font-size: 25px;
		}
		.time {
			font-size: 18px;
		}
		.footer {
			margin-bottom: 0px;
		}
		ul.countdown {
			margin-top: 30px;
			margin-bottom: 20px;
		}
		.add-padding {
			padding-top: 0;
		}
	}

	@media (min-width: 768px) and (max-width: 991px) {
		#event {
			margin-top: 65px;
		}
		.tm-parallax {
			height: 100%;
		}
		.tm-parallax-header {
			padding-top: 5px;
			padding-bottom: 35px;
		}
		.text-after span {
			font-size: 30px;
		}
		.add-padding {
			padding-top: 0;
		}
	}

	/* Mobile */
	@media (max-width: 767px) {
		#event {
			margin-top: 35px;
		}
		.tm-parallax {
			height: 100vh;
		}
		.tm-parallax-header {
			padding-top: 35px;
			padding-bottom: 35px;
		}
		.submit-button {
			padding-bottom: 60px;
		}
		ul.countdown li span {
			font-size: 50px;
		}
		ul.countdown li.seperator {
			font-size: 40px;
		}
		.day {
			font-size: 22px;
		}
		.time {
			font-size: 20px;
		}
		.footer {
			margin-top: 60px;
		}
		.text-after span {
			font-size: 30px;
		}
		.add-padding {
			padding-top: 0;
		}
	}

	@media (max-width: 480px) {
		.tm-parallax {
			height: 100%;
		}
	}

	@media (max-width: 360px) {
		#event {
			margin-top: 65px;
		}
		.tm-parallax {
			height: 100%;
		}
		.tm-parallax-header {
			padding-top: 5px;
			padding-bottom: 35px;
		}
		#event {
			margin-bottom: 5px;
		}
		.submit-button {
			padding-bottom: 30px;
		}
		.day {
			font-size: 20px;
		}
		.time {
			font-size: 18px;
		}
		.footer {
			margin-bottom: 0px;
		}
		ul.countdown {
			margin-top: 30px;
			margin-bottom: 20px;
		}
		.add-padding {
			padding-top: 0;
		}
	}

	@media (max-width: 320px) {
		.tm-parallax {
			height: inherit;
		}
	}
</style>
