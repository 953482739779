<template>
	<div>
        <div class="contact-box" style="padding-bottom: 20px;">
            <div class="container">
                <div class="row text-center justify-content-center">
                    <div class="col-lg-12">
                        <img src="/images/ruler-1.png" alt="" width="300px"><br><br>
                        <div class="title-box wow zoomInDown" id="couple">
                            <img src="/images/bismillah.png" alt="" width="150">
                            <h5 class="pt-10">
                                <i>Assalamualaikum Warahmatullahi Wabarakatuh</i>
                            </h5>
                            <p>Tanpa mengurangi rasa hormat, Kami mengundang Bapak/ Ibu/ Saudara/i serta kerabat sekalian untuk menghadiri acara pernikahan kami:</p>
                        </div>
                    </div>
                </div>
                <div class="row text-center justify-content-center">
                    <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="event-inner wow fadeIn" style="box-shadow: none;">
                            <div class="event-img" style="padding-bottom: 30px;">
                                <img class="img-fluid" src="/photos/couple/1-2.jpg" alt="">
                            </div>
                            <h2 class="display-font custom-font-color">{{ this.female }}</h2>
                            <p>Putri dari<br/>Bapak Maryono dan Ibu Lasmi</p>
                        </div>
                        <i class="d-block custom-font-color" style="font-size: 12px; margin-top: -40px;">
                            dengan
                        </i>
                        <div class="event-inner wow fadeIn" style="box-shadow: none;">
                            <h2 class="display-font custom-font-color">{{ this.male }}</h2>
                            <p>Putra dari<br/>Bapak H. Setia Widodo dan Ibu Hj. Maroni</p>
                        </div>
                    </div>
                </div>
                <!-- <center><img src="/photos/couple/flower-bottom.png" alt="" width="300px" ></center><br><br> -->

                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="title-box wow wow-not-repeat zoomIn" data-wow-delay="0.5s">
                                <hr>
                                <span class="mbr-fonts-style display-font" style="font-size: 18px;">
                                    “Dan diantara tanda-tanda kekuasaan-Nya ialah diciptakan-nya pasangan hidup dari jenismu sendiri
                                    supaya kamu mendapatkan ketenangan hati, dan dijadikan-Nya Kasih Sayang diantara kamu,
                                    Sesungguhnya yang demikian itu menjadi tanda-tanda kebesaran-Nya bagi orang-orang yang berfikir.”
                                </span>
                                <br><hr>
                                <i><b>QS. Ar-Rum : 21</b></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Couple',
    data() {
		return {
            resultName: ''
        }
    },
    methods: {
        copyText(id) {
            var copyText = document.getElementById(id);
            var input = document.createElement("textarea");
            input.value = copyText.textContent;
            document.body.appendChild(input);
            input.select();
            document.execCommand("Copy");
            input.remove();
            this.$toast.default('Copied', {
				position: "bottom",
				dismissible: true
			})
        }
    },
    created() {
		this.resultName = this.coupleName.split(" ");
	},
}
</script>

<style scoped>
    @media (min-width: 361px) and (max-width: 767px) {
        h2.display-font {
            font-size: 28px;
        }
    }

    @media (max-width: 360px) {
        h2.display-font {
            font-size: 26px;
        }
    }
</style>